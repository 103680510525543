// special offer
@use "../../scss/gradients";
@use "../../scss/mixins";
@import "./../../../node_modules/bootstrap/scss/variables";

#special-offer {
    $base-text-color: rgb(70, 70, 70, 1);
    $base-background: rgb(246, 246, 246);
    $bg-carbon: rgb(35, 35, 35);

    @include mixins.stripe(#d10910);
    @include mixins.title(white);
    @include mixins.subtitle(rgba(white, 0.75));

    background: linear-gradient(137deg, rgb(0, 0, 0) 45%, rgba(255, 0, 0, 0.75) 90%);
    background-attachment: fixed;

    .bg-gradient-sun {
        @include gradients.sun
    }

    .custom-block {
       
        &[data-index="0"] {
            & .wrapper {
                background-position-y: 0%!important;
            }
        }

        &[data-index="1"] {
            & .wrapper {
                background-position-y: 50%!important;
            }
        }

        &[data-index="2"] {
            & .wrapper {
                background-position-y: 100%!important;
            }
        }

        .delivery-box-number {
            width: 60px;
            background: rgb(188,8,14);
            background: linear-gradient(180deg, rgba(188,8,14,1) 20%, rgba(209,9,16,1) 50%, rgba(213,33,39,1) 80%); 
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 0.5rem 0.75rem;

            @include media-breakpoint-down(xxl) {
                width: 40px;
            }
            @include media-breakpoint-down(sm) {
                width: 30px;
            }

            .vertical-text {
                font-family: inherit;
                font-size: 1.4rem;
                font-weight: 500;
                letter-spacing: 0.017em;
                text-transform: uppercase;
                color: white;
                writing-mode: vertical-lr;
                -ms-writing-mode: tb-lr;
                transform: rotate(180deg);

                @include media-breakpoint-down(xxl) {
                    font-size: 1.25rem;
                }

                @include media-breakpoint-down(sm) {
                    font-size: .8rem;
                }
    
            }
        }

        span {
            &.issue {
                font-family: inherit;
                font-size: 2.5rem;
                font-weight: 700;
                display: inline-flex;
                text-transform: uppercase;
                width: 60px;
                height: 60px;
                background-color: white;
                border-radius: 9rem;
                color: rgba(209, 9, 16, 0.75);
                justify-content: center;
                align-items: center;

                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

                @include media-breakpoint-down(xxl) {
                    width: 50px;
                    height: 50px;
                    font-size: 2rem;
                }

                @include media-breakpoint-down(sm) {
                    width: 30px;
                    height: 30px;
                    font-size: 1.4rem;
                }
            }

            &.price {
                font-size: 2.5rem;
                color: #FCE205;
                //background: #464646;
                background: linear-gradient(180deg, rgb(34, 34, 34) 0%, rgb(68, 68, 68) 50%, rgb(85, 85, 85) 100%);
                //padding: 0rem 2rem .25rem;
                padding: .25rem 2rem ;
                border-radius: 9rem;
                font-weight: 700;

                @include media-breakpoint-down(xxl) {
                    font-size: 2.2rem;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 1.5em;
                    padding-top: 4px;
                }

            }

            &.oldPrice {
                text-decoration-line: line-through;
                text-decoration-thickness: 2px;
                text-decoration-color: #d10910ed;
                font-weight: 500;
                font-size: 1.25rem;

                @include media-breakpoint-down(sm) {
                    font-size: 1rem;
                }
            }
        }

        .custom-block-inner {

            @include media-breakpoint-down(lg) {
            //min-width: 560px;
            //min-height: 212px;
            }

            >.wrapper {
                position: relative;
                font-family: inherit;
                //text-transform: uppercase;
                font-size: 1.5rem;
                line-height: 1.7em;
                font-weight: 700;
                color: #535353;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                //background: linear-gradient(180deg, rgb(211, 211, 211) 0%, rgba(183, 183, 183, 0.25) 50%, rgb(211, 211, 211) 100%), url('../../media/div-gr.jpg');
                //background: linear-gradient(180deg, rgb(200, 200, 200) 0%, rgba(183, 183, 183, 0.25) 50%, rgb(230, 230, 230) 100%), url("media/div-gr.jpg");
                background: linear-gradient(180deg, rgb(89, 89, 89) 0%, rgba(255, 253, 253, 0) 50%, rgb(172, 172, 172) 100%), url("media/div-gr.jpg");
                background-size: cover;
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

                @include media-breakpoint-down(sm) {
                        font-size: .8rem;
                        font-weight: 600;
                    }

                .block-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .block-issue {
                    @include media-breakpoint-down(sm) {
                       max-width: 120px;
                    }
                }

                .line {
                    position: absolute;
                    bottom: .5rem;
                    right: .5rem;
                    height: 50px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        height: 35px;
                    }

                    .discount {
                        margin-left: auto;
                        height: 100%;
                        width: fit-content;

                        img {
                            height: 100%;
                        }
                    }
                }

                .last-line {
                    font-size: 1.2rem;
                    font-weight: 700;

                    @media (max-width: 575.98px) { 
                        font-size: .8rem;
                        font-weight: 600;
                     }
            }
        

            }
        }
    }

       

    // .clip-path-1 {
    //     position: relative;

    //     span {
    //         &:first-child {
    //             position: relative;
    //             width: 100%;
    //             height: 100%;
    //             clip-path: polygon(100% 0%, 0% 0%, 65% 100%);
    //             background-color: #ffdadb;
    //         }

    //         &:last-child {
    //             position: absolute;
    //             width: 65%;
    //             height: 65%;
    //             clip-path: polygon(100% 0%, 0% 0%, 65% 100%);
    //             background-color: #d10910;
    //             top: 10%;
    //             left: -5%;
    //         }
    //     }

    // }

    // .clip-path-2 {
    //     position: relative;

    //     span {
    //         &:first-child {
    //             position: relative;
    //             display: block;
    //             width: 100%;
    //             height: 100%;
    //             clip-path: polygon(100% 0%, 0% 0%, 65% 100%);
    //             background-color: #ffdadb;
    //         }

    //         &:last-child {
    //             position: absolute;
    //             display: block;
    //             width: 85%;
    //             height: 85%;
    //             clip-path: polygon(100% 0%, 0% 0%, 65% 100%);
    //             background-color: #d10910;
    //             top: 15%;
    //             right: -15%;
    //         }
    //     }
    // }

    // .text-block-wrapper {
    //     color: $base-text-color;
    //     font-family: inherit;

    //     img {
    //         &.collage {
    //             background-color: $base-background;
    //             padding: .75rem;
    //         }
    //     }



    //     span {
    //         color: #d10910;
    //     }

    //     .line-3,
    //     .line-2,
    //     .line-4 {
    //         span {

    //             &.issue {
    //                 width: 50px;
    //                 display: inline-flex;
    //                 height: 50px;
    //                 justify-content: center;
    //                 border-radius: 9rem;
    //                 background: $base-background;
    //                 align-items: center;
    //                 font-size: 2.25rem;
    //             }

    //             &.price {
    //                 background: #f6f6f6;
    //                 padding: .25rem 1.5rem;
    //                 border-radius: 10px;
    //                 display: inline-block;
    //                 font-size: 2.25rem;
    //             }
    //         }
    //     }
    // }
}