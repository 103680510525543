// gallery

@use '../../scss/gradients';
@use '../../scss/mixins';

#thumbnails {

    $base-text-color: rgb(240, 248, 255);
    $img-color: rgba(70, 70, 70, 1);

    @include mixins.title(rgb(70, 70, 70));
    @include mixins.subtitle(rgba(70, 70, 70, 0.75));
    @include mixins.stripe(#d10910);

    .mobile-adopt {

        max-width: 460px;
        margin-bottom: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        //padding: 1rem;

        .adopt-img {
            height: 350px;
        }

        .adopt-text {
            height: 80px;
            border-radius: 0 0 5px 5px;

            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

        }
    }


    .bg-gradient-frost {
        //@include gradients.frost
        //background-color: var(--carbon-color);
        background-color: rgb(246, 246, 246);
    }

    .separator-right {
        border-right: 3px solid rgba(70, 70, 70, 0.5);
    }

    .separator-left {
        border-left: 3px solid rgba(70, 70, 70, 0.5);
    }

    .maxwidth-400 {
        max-width: 400px;
    }

    .height-fit-content {
        height: fit-content;
    }

    .wrapper {
        cursor: pointer;
        //max-width: 400px;
        border-radius: 5px;
        line-height: 1.2em;
        font-weight: 600;
        color: $img-color;
        font-size: .8rem;
        // margin-left: auto;
        // margin-right: auto;
        background-color: rgba(0, 0, 0, 0.1); //! background
        border: 1px solid rgb(223, 223, 223);

        img {
            transition: .15s ease-in-out;
            transform: scale(1);
            opacity: .9;
        }

        &:hover {
            img {

                transform: scale(1.025);
                opacity: 1;
                transition: opacity .15s, transform .5s ease-in-out;
            }
        }

        .description {
            border-top: 1px solid #dfdfdf;
        }
    }
}

// moddl
.gallery-modal {
    #contained-modal-title-vcenter {
        &.modal-title {
            &.h4 {
                font-size: 1rem;
                font-weight: 500;
                color: rgb(70, 70, 70);
            }
        }
    }
}