// gradients

@mixin frost {
    background: #000428;
    background: -webkit-linear-gradient(to right, #004e92, #000428);
    background: linear-gradient(to right, #004e92, #000428);
}

@mixin sun {
    background: #f8b500;
    background: -webkit-linear-gradient(to right, #fceabb, #f8b500);
    background: linear-gradient(to right, #fceabb, #f8b500);
}