//logo
@mixin logo() {

    .logo_block {
        padding: 1rem;
        width: 600px;
        position: relative;
        //inset: 0 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        transform: scale(.8);
        left: 0;
        top: 4%;

        @media (max-width: 575.98px) {
            transform: scale(.4);
            left: -28%;
            top: -8%;
        }

        @media (min-width: 576px)and (max-width: 767.98px) {
            transform: scale(.5);
            left: -22%;
            top: -6%;

        }

        @media (min-width: 768px) and (max-width: 991.98px) {
            transform: scale(.6);
            left: -18%;
            top: -4%;
        }

        @media (min-width: 992px) and (max-width: 1199.98px) {
            transform: scale(.6);
            left: -17%;
            top: -4%;
        }

        @media (min-width: 1200px) and (max-width: 1399.98px) {
            transform: scale(.7);
            left: -12%;
            top: -2%;
        }

        &::after {
            content: "";
            background: black;
            display: block;
            clip-path: polygon(0% 0%, 95.3% 100%, 100% 0%);
            position: absolute;
            width: 60%;
            height: 25%;
            top: 2px;
            right: 75px;
            z-index: 1;
        }

        &::before {
            content: "";
            background: #d10a0f;
            display: block;
            clip-path: polygon(0% 0%, 94.8% 100%, 100% 0%);
            position: absolute;
            width: 60%;
            height: 25%;
            z-index: 3;
            transform: rotate(180deg);
            bottom: 18px;
            left: 91px;
        }

        .logo {
            height: 170px;
            overflow: hidden;
            position: relative;
            z-index: 2;
        }

        .logo_2 {
            height: 85px;
            overflow: hidden;
            position: relative;
            top: -10px;
            margin-left: 5rem;
            z-index: 3;
        }

        .anim {
            font-size: 5rem;
            font-weight: 600;
            transform: scaleY(2.5)skew(-30deg);
            background: #d10a0f;
            color: rgba(aliceblue, .9);
            max-width: fit-content;
            padding: 0 2.25rem;
            text-transform: uppercase;
            position: relative;
            top: 1.35rem;
            letter-spacing: 0.075em;
            left: 20px;
        }

        .anim_2 {
            font-size: 2.5rem;
            font-weight: 600;
            transform: scaleY(2.3)skew(-30deg);
            background: black;
            color: rgba(aliceblue, .9);
            max-width: fit-content;
            padding: 0 1.5rem;
            text-transform: uppercase;
            position: relative;
            letter-spacing: 6px;
            left: 20px;

            span {
                position: relative;
                top: 4px;
            }
        }
    }
}