//description
@use '../../scss/mixins';
@import "./../../../node_modules/bootstrap/scss/variables";

$text-color: rgba(70, 70, 70, 1);
$bg-color: white;
$default-bg-color: #f6f6f6;
$img-bg: rgba(0, 0, 0, 0.1);
$default-red: rgb(209, 9, 16);

#description {
    

    @include mixins.stripe($default-red);
    @include mixins.title($text-color);
    @include mixins.subtitle(rgba(70, 70, 70, 0.75));

    .row {
        .separator-right {
            border-right: 3px solid rgba(70, 70, 70, .5);
        }

        .img-wrapper {
            background-color: $img-bg;
            border-radius: 5px 5px 0 0;
            text-align: center;

            .dimensions{
                max-width: 25%;
                width: 180px;
                right: 4%;
                bottom: 15%;
                left: auto;
                top: auto;
                
                img {
                    width: 100%;
                }
            }

            .metal-parts-sticker {
                max-width: 25%;
                width: 180px;
                right: 4%;
                bottom: 4%;
                left: auto;
                top: auto;
                
                img {
                    width: 100%;
                }
            }

            img {
                max-width: 856px;

                @include media-breakpoint-only(lg) {
                    width: 606px;
                }

                @include media-breakpoint-only(xl) {
                    width: 736px;
                }

                @include media-breakpoint-only(xxl) {
                    width: 856px;
                }

            }

            .sticker {
                position: absolute;
                top: .5rem;
                bottom: auto;
                left: .5rem;
                right: auto;
                max-width: 40%;
            }


            .cars-description {
                position: absolute;
                //display: flex;
                flex-direction: column;
                justify-content: center;

                width: 250px;
                height: 130px;
                color: black;
                font-family: inherit;
                font-size: 1.2rem;
                line-height: 1.2em;
                font-weight: 400;
                clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
                //background-color: $default-bg-color;

                &[data-index="0"] {
                    color: $text-color;
                    top: 4.5rem;
                    bottom: auto;
                    left: 7rem;
                    right: auto;
                    z-index: 1;
                    clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
                    padding-left: 1.5rem;
                    align-items: start;
                    transform: scale(1);
                    transition: transform .25s cubic-bezier(1, 0, 0, 1);

                    @include media-breakpoint-down(xxl) {
                        top: 3rem;
                        left: 5rem;
                        bottom: auto;
                        right: auto;
                        transform: scale(.85);
                        transition: transform .25s cubic-bezier(1, 0, 0, 1);
                    }

                    @include media-breakpoint-down(xl) {
                        top: 2rem;
                        left: 2.5rem;
                        bottom: auto;
                        right: auto;
                        transform: scale(.75);
                        transition: transform .25s cubic-bezier(1, 0, 0, 1);
                    }

                }

                &[data-index="1"] {
                    color: $text-color;
                    bottom: 2rem;
                    left: auto;
                    right: 1rem;
                    top: auto;
                    z-index: 1;
                    clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
                    align-items: end;
                    padding-right: 1.5rem;
                    transform: scale(1);
                    transition: transform .25s cubic-bezier(1, 0, 0, 1);

                    @include media-breakpoint-down(xxl) {
                        bottom: 1rem;
                        right: 0;
                        top: auto;
                        left: auto;
                        transform: scale(.85);
                        transition: transform .25s cubic-bezier(1, 0, 0, 1);
                    }

                    @include media-breakpoint-down(xl) {
                        bottom: 0rem;
                        right: -1.5rem;
                        top: auto;
                        left: auto;
                        transform: scale(.75);
                        transition: transform .25s cubic-bezier(1, 0, 0, 1);
                    }

                }

                &::before {
                    content: "FERRARI F2007";
                    display: flex;
                    font-weight: 500;
                    font-size: 1.25rem;
                    color: $default-red;
                    text-decoration: underline;
                    text-decoration-skip-ink: auto;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 3px;
                }
            }
        }
    }

    .inner {
        font-family: inherit;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5em;
        color: $text-color;
        letter-spacing: 0.35px;

        position: relative;
        padding-top: 3rem;
    }
}