@mixin title($title-color) {
    .title {
        @media (max-width: 1399.98px) {
            font-size: 2rem
        }

        font-family: inherit;
        color: $title-color;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1.1em;
    }
}

@mixin subtitle($subtitle-color) {
    .subtitle {
        @media (max-width: 1399.98px) {
            font-size: 1rem
        }

        font-family: inherit;
        color: $subtitle-color;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.5em;
        letter-spacing: .35px;
        text-justify: auto;

        // text-size-adjust
        -webkit-text-size-adjust: auto;
        -moz-text-size-adjust: auto;
        -ms-text-size-adjust: auto;
        text-size-adjust: auto
    }
}

@mixin stripe($stripe-color) {
    tr {
        th {
            $th-width: 10px;

            &:first-child {
                position: relative;
                width: $th-width;

                .title-decoration {
                    position: absolute;
                    width: 50%;
                    top: 6%;
                    bottom: 6%;
                    background-color: $stripe-color;
                }
            }
        }
    }
}

@mixin accordionButton($transform: false) {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    margin-top: -5px;

    @if $transform {
        transform: var(--bs-accordion-btn-icon-transform);
    }
}