// модальное окно выбора языка

$bg-color: #f6f6f6;
$orange-color: #ff5100;
$sea-lord-first: rgba(156, 252, 248, 1);
$sea-lord-second: rgba(110, 123, 251, 1);

.modal-backdrop {
    &.choose_your_language_modal-backdrop {
        background: none !important;
    }
}

.choose_your_language_modal {
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.15);
    border: none;

    .modal-header {
        border-bottom: none;

        .modal-title {
            width: 100%;
            text-align: center;
            font-size: 1rem;
            line-height: 1.2em;
            font-weight: 400;
            padding: 1rem;
            color: rgb(70,70,70);

            br {
                margin-bottom: 1rem;
            }
        }
    }

    .modal-footer {
        border-top: none;
    }

        .choose_your_language_btn {
            min-width: 140px;
            border: none;
            background-color: #A9A9A9;

            font-size: 14px;
    
            &:hover,
            &:visited,
            &:focus,
            &:focus-within {
                background-color: #9b9b9b;
            }
        }
}
