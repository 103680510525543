footer {

    $base-color: #f2f2f2;

    menu {
        button {
            border: none;
            background: none;
            color: $base-color;
            transition: .25s;
            text-decoration: none;

            &:hover {
                color: white;
                text-decoration: underline;
                transition: .25s;
            }
        }

        //social metworks hover disabled
        button[data-index="4"]:hover {
            color: $base-color;
            text-decoration: none;
        }

        hr {
            color: #f2f2f2
        }
    }

    .fizi-logo {
        text-align: center;

        img {
            width: 100%;
            height: auto;
            max-width: 100px;
        }
    }

    .contact {
        color: $base-color;
        a {
            color: $base-color;
        }

        p {
            font-size: .9rem;
        }
    }
}