//advantage
@import "./../../../node_modules/bootstrap/scss/variables";

#profit {

    //$base-color: rgb(70, 70, 70);
    $base-color: rgb(246, 246, 246);
    background: linear-gradient(137deg, rgb(0, 0, 0) 45%, rgba(255, 0, 0, 0.75) 90%) fixed;
    .row {
        //background: $sea-lord-second;
        //background-color: white;
        padding: 2rem 0;
        border-radius: 5px;

        div:nth-child(1) {
            display: flex;
            font-size: 2.5rem;
            font-weight: 700;
            line-height: 1.2em;
            font-family: GolosText;
            letter-spacing: .35px;
            text-transform: uppercase;

            color: $base-color;
            text-align: center;
            justify-content: center;
            align-items: center;

            // button {
            //     background: hsla(19, 100%, 50%, 1);
            //     background: linear-gradient(180deg, hsla(19, 100%, 50%, 1) 0%, hsla(25, 90%, 62%, 1) 50%, hsla(19, 100%, 50%, 1) 100%);
            //     background: -moz-linear-gradient(180deg, hsla(19, 100%, 50%, 1) 0%, hsla(25, 90%, 62%, 1) 50%, hsla(19, 100%, 50%, 1) 100%);
            //     background: -webkit-linear-gradient(180deg, hsla(19, 100%, 50%, 1) 0%, hsla(25, 90%, 62%, 1) 50%, hsla(19, 100%, 50%, 1) 100%);
            //     border-radius: 3rem;
            // }
        }

        div:nth-child(2) {

            tr {
                line-height: 1.8em;

                th {
                    color: $base-color;
                    //font-family: GolosText;
                    font-weight: 500;
                    font-size: 1.5rem;
                    line-height: 1.2em;
                    letter-spacing: .35px;
                    vertical-align: top;

                    @include media-breakpoint-down(lg) {
                        font-size: 1.25rem;
                        line-height: 1.4em;

                     }


                    &:last-of-type {
                       padding-bottom: 1rem;
                    }
                }
            }
        }
    }
}