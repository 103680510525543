@import "../node_modules/bootstrap/scss/bootstrap";

@import "./Components/Introduction/intro.scss",
"./Components/NavigationBar/navigation.scss",
"Components/ChooseYourLanguage/choose_your_language",
"./Components/Footer/footer",
"./Components/Introduction/intro",
"./Components/Description/description.scss",
"./Components/Profit/advantage",
"./Components/Offer/offer",
"./Components/Collection/collection",
"./Components/Gallery/gallery",
"./Components/Faq/faq";


// body {

//     background-image: url("../public/images/FT_track_shadow.png");
//     background-size: auto;
//     background-repeat: no-repeat no-repeat;
//     background-attachment: fixed;
// }

:root {
    $bg-color: #f6f6f6;
    $orange-color: #ff5100;
    $sea-lord-first: rgba(156, 252, 248, 1);
    $sea-lord-second: rgba(110, 123, 251, 1);

    --bs-accordion-bg: #0d6efd !important;

    $color-light-black: rgba(70, 70, 70, 1);
    --carbon-color: rgba(35, 35, 35, 1)
}

#transporter_template {

    max-width: 2048px;
    //min-height: 100vh;
        margin-left: auto;
        margin-right: auto;
}


@font-face {
    font-family: RedHatRegular;
    src: url(./fonts/RedHatDisplay/RedHatDisplay-Regular.ttf);
}

@font-face {
    font-family: RedHatMedium;
    src: url(./fonts/RedHatDisplay/RedHatDisplay-Medium.ttf);
}

@font-face {
    font-family: RedHatBold;
    src: url(./fonts/RedHatDisplay/RedHatDisplay-Bold.ttf);
}

@font-face {
    font-family: RedHatBlack;
    src: url(./fonts/RedHatDisplay/RedHatDisplay-Black.ttf);
}


@font-face {
    font-family: GolosRegular;
    src: url(./fonts/Golos/Golos-UI_Regular.ttf);
}

@font-face {
    font-family: GolosMedium;
    src: url(./fonts/Golos/Golos-UI_Medium.ttf);
}

@font-face {
    font-family: GolosBold;
    src: url(./fonts/Golos/Golos-UI_Bold.ttf);
}

@font-face {
    font-family: GolosText;
    src: url(./fonts/GolosText/GolosText-VariableFont_wght.ttf);
}

*,
html,
body {
    font-family: GolosText;
}

#root {
    background-color: rgb(50, 50, 50);
}


// -- GLOBAL CLASSES -- 

main {
    min-width: 350px;
}

.red-hat-black {
    font-family: RedHatBlack;
}

.golos-medium {
    font-family: Golosmedium;
}

.golos-bold {
    font-family: GolosBold;
}

.golos-text {
    font-family: GolosText;
}

.sea-lord-bg-grad {
    background-image: linear-gradient(109.6deg, rgba(156, 252, 248, 1) 11.2%, rgba(110, 123, 251, 1) 91.1%);
}

.lawrencium-bg-grad {
    background-image: linear-gradient(to right, #0f0c29, #302b63, #24243e);
    opacity: 0;
    animation: opacity .35s forwards;
}

.bg-light-gray {
    background-color: #f6f6f6;
}

.gradient-1 {
    background-image: linear-gradient(94.3deg, rgba(26, 33, 64, 1) 10.9%, rgba(81, 84, 115, 1) 87.1%);
}

@keyframes opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.h-16px {
    height: 16px;
}

.h-15px {
    height: 15px;
}

.h-14px {
    height: 14px;
}

.h-13px {
    height: 13px;
}

.mb-2px {
    margin-bottom: 2px;
}

.mb-3px {
    margin-bottom: 3px;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background: #d10910;
    transform-origin: 0%;
    z-index: 10001
}

.btn-wrapper {
    justify-content: start;
  
   @media (max-width: 991.98px) {
    justify-content: center;
   }
}

.width-fit-content {
    width: fit-content
}

span {

    &.alert {

        color: red;
        padding: 0;
        background-color: yellowgreen;
    }

    &.uwaga {
        background-color: rgba(173, 216, 230, 0.25);
        padding: 0 .5rem .15rem .5rem;
        border-radius: 4px;
        color: inherit;
    }

    &.red-highlight {
        color: rgb(209, 9, 16);
    }

    &.fs-sm {
        font-size: .75rem;
    }
}

.modal-link {
    text-decoration: underline dashed;
    text-underline-offset: 3px;
    color: inherit;
    margin-bottom: 0;
    cursor: pointer;

    &:hover {
        text-decoration-style: solid;
        color: var(--bs-link-hover-color)
    }
}

.modal-iframe-wrapper {
    height: calc(100vh - 190px);
}

#contained-modal-title-vcenter {
    font-size: 1.2rem;
}
