//collection
@use "../../scss/mixins";

#collection {

    $bg-collection: lightslategray;
    $bg-collection-2: rgb(111, 111, 111);
    $title-color: rgb(240, 248, 255);
    $subtitle-color: rgba(240, 248, 255, 0.75);

    @include mixins.stripe($default-red);
    @include mixins.title($title-color);
    @include mixins.subtitle($subtitle-color);

    background-color: $bg-collection-2;
    

    .collectionSwiper {

        .wrapper {

            margin-bottom: 1rem;

            .img-area {
                overflow: hidden;
                position: relative;
                cursor: pointer;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, .1);
                    z-index: 1;
                }

                img {
                    //transform: scale(1);
                    transition: all .25s;
                }

                &:hover {

                    img {
                        //transform: scale(1.01);
                        transition: all .25s;
                    }

                    &::before {
                        background-color: rgba(0, 0, 0, 0);
                    }
                }
            }


            .info-area {
                padding: .5rem 0;
                font-size: 1rem;
                line-height: 1.2em;
                color: white;

                .issue-number {
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }
    }

    #mySelect {

        //width: 310px;
        height: 40px;

        font-family: inherit;
        font-size: .9rem;
        letter-spacing: 0.35px;
        font-weight: 500;
        color: #464646;
        text-transform: uppercase;

        //text-align: center;
        padding: 0 1.5rem
    }

    #collection-manual-select {

        select {
            &#mySelect {
                border: none;
                border-radius: 9rem;
            }
        }

        .swiper-custom-prev,
        .swiper-custom-next {

            border: none;
            background: none;
            color: rgb(245, 245, 245);
            font-size: 2.5rem;
            display: inline-flex;
            justify-content: center;
            align-items: flex-start;

            &.swiper-button-disabled {
                color: rgb(190, 190, 190);
            }
        }
    }
}

// moddl
.collection-modal {
    #contained-modal-title-vcenter {
        &.modal-title {
            &.h4 {
                font-size: 1rem;
                font-weight: 500;
                color: rgb(70, 70, 70);
            }
        }
    }
}

//downloadPdf--icon
.downloadPdf--icon {
    path {
        fill: whitesmoke;

        &:last-child {
            fill: #ff4e4e;
        }
    }

}