// navigation

@mixin navLink() {
    font-size: .875rem;
    line-height: 1.5em;
    font-weight: 500;
    letter-spacing: .022rem;
    text-transform: uppercase;

    padding: .3rem 1rem !important;
    border-radius: 9rem;
}

nav {
    &.navbar {
        padding-top: .5rem;
        padding-bottom: .5rem;
        width: 100%;
    }

    button {
        border: none;
        background: none;
        color: var(--bs-nav-link-color);

        &.nav-link {
            @include navLink();

            &:hover {
                color: rgba(black, .75);
            }

            &.active {
                color: rgba(black, .75);
                background-color: rgba(lightgray, .5);
            }
        }
    }

    a {
        &.dropdown-toggle {

            &.nav-link {

                @include navLink();
                color: var(--bs-nav-link-color);
                // background-color: rgba(lightgray, .5);

                span {
                    margin-right: 1rem;
                }

                svg {
                    font-size: 0.938rem;
                }
            }

            &:hover,
            &.show {
                background-color: rgba(lightgray, .5);
                color: var(--bs-navbar-active-color);
            }
        }
    }

    .dropdown-menu {
        &.show {
            background-color: white;

            a {
                margin-bottom: .25rem;
            }
        }
    }
}

//mobile

@media (max-width: 991.98px) {
    nav {
        .navbar-brand {
            padding: 0 !important;
        }

        #responsive-navbar-nav {
            .navbar-nav {
                margin-top: 3rem;

                .nav-link {
                    font-size: 1rem;
                    font-weight: 500;
                    font-family: inherit;
                    line-height: 1.5em;
                    background: lightslategray;
                    padding: .5rem 1rem !important;
                    margin: 0 auto 1rem;
                    color: white;
                    max-width: 400px;
                    min-width: 300px;
                    width: 100%;
                    text-align: center;
                }

                &.second {

                    .button-wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        margin-bottom: 3rem;
                        margin-top: 1rem;

                        button {
                            width: 50px;
                            height: 50px;
                            background: lightslategray;
                            border-radius: 9rem;
                            font-size: 1.25rem;
                            color: white;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &.login {
                                margin-left: 2rem;
                            }

                        }
                    }
                }
            }
        }
    }
}

//buttons

@media (min-width: 992px) {
    nav {
        #responsive-navbar-nav {
            .navbar-nav {
                &.second {
                    .button-wrapper {
                        button {
                            width: 32px;
                            height: 32px;
                            background: rgba(226, 226, 226, 0.75);
                            border-radius: 9rem;
    
                            svg {
                                margin-top: -3px;
                            }
    
                            &:hover {
                                background: rgba(226, 226, 226, 1);
                            }
    
                            
                        }
                    }
                }
            }
        }
    }
}
