//faq

@use '../../scss/mixins';

#faq {

    button {
        #reset-style {
            border: none!important;
            background: none!important;
        }
    }

    $base-text-color: rgba(70, 70, 70, 1);
    color: $base-text-color;

    @include mixins.stripe(#d10910);
    @include mixins.title($base-text-color);
    @include mixins.subtitle(rgba(70, 70, 70, 0.75));

    .accordion {
        --bs-accordion-bg: transparent !important;
        --bs-accordion-active-bg: transparent;


        .accordion-item {

            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: 2px solid #bbb;
            border-radius: 0;
        }

        button[aria-expanded="false"].accordion-button {
            color: inherit;

            &::before {
                @include mixins.accordionButton;
            }

            &::after {
                display: none;
            }
        }

        button[aria-expanded="true"].accordion-button {

            color: orange;

            &::before {
                @include mixins.accordionButton($transform: true);
            }

            &::after {
                display: none;
            }
        }

        .accordion-button {
            padding: 1.25rem .5rem;
            letter-spacing: .022rem;

            &:active,
            &:focus-visible,
            &:visited,
            &:focus,
            &:focus-within {
                background-color: unset;
                border: none;
                box-shadow: none;
            }
        }
    }

    // @media (max-width: 1399.98px) {
    //     .title {
    //         font-size: 2rem;
    //     }

    //     .subtitle {
    //         font-size: 1rem;
    //     }
    // }
}