// introduction

@use "./logo/logo";

$main-bg-color: rgba(0, 0, 0, 0.25);

#introduction {

    @include logo.logo();

    .intro {
        position: relative;
        display: flex;
        height: calc(100vh - (72px + 5px));
        max-height: 100vh;
        min-height: 670px;
        overflow: hidden;

        @media (max-width: 575.98px) {
            height: calc(100vh - (72px + 5px + 53px));
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $main-bg-color;
            //background: linear-gradient(163deg, rgba(0, 0, 0, 0.85) 10%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.15) 85%);
            background: linear-gradient(163deg, rgba(0, 0, 0, 0.75) 10%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0.15) 85%);
            border-radius: 5px;
            z-index: 1;
        }

        .transporter-logo {
            position: absolute;
            width: 600px;
            height: 100%;
            z-index: 2;
        }

        .logos {
            position: absolute;
            top: 0;
            right: 0;
            bottom: auto;
            left: auto;
            width: 25vw;
            height: 100%;
            min-width: 40%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;

            .sticker--two-bolids {
                max-width: 200px;
                position: relative;
                -ms-flex-item-align: end;
                -ms-grid-row-align: end;
                align-self: end;
                right: 5%;
                left: auto;
                bottom: 3%;
                top: auto;
                z-index: 5;
            }

            .logo-ferrari {
                position: relative;
                z-index: 2;
                -ms-flex-item-align: end;
                -ms-grid-row-align: end;
                align-self: end;
                top: 4%;
                right: 10%;
                left: auto;
                bottom: auto;
                max-width: 60px;

                @media (max-width: 575.98px) {
                    max-width: 40px
                }
            }
        }

        .wrapper {
            max-width: 1200px;
            max-height: 750px;
            height: 100%;
            position: relative;
            align-self: center;

            @media (max-width: 575.98px) {
                top: 1.5rem;
                left: 0
            }

            @media (min-width: 576px)and (max-width: 767.98px) {
                top: 1rem;
                left: 0;
            }

            @media (min-width: 768px) and (max-width: 991.98px) {
                top: 0;
                left: 5rem;
            }

            @media (min-width: 992px) and (max-width: 1199.98px) {
                top: 1rem;
                left: 20rem
            }

            @media (min-width: 1200px) and (max-width: 1399.98px) {
                left: 25rem;
                top: 0;
            }

            left: 40rem;
            top: 0;
            bottom: auto;
            right: auto;

            img {
                height: 100%;

            }
        }
    }

    .title {
        position: absolute;
        //inset: auto 0 1.5rem 1.5rem;
        top: auto;
        right: 0;
        bottom: 1.5rem;
        left: 1.5rem;
        height: auto;
        max-width: 600px;
        color: rgba(black, .75);
        font-family: inherit;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.5em;
        text-transform: uppercase;
        //text-shadow: -1px 0 5px rgba(0, 0, 0, 0.25);
        padding: 1.5rem;
        background: linear-gradient(45deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.25) 100%);
        background-color: rgba(255, 255, 255, 0.35);
        border-radius: 5px;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        z-index: 4;

        @media (max-width: 1399.98px) {

            font-size: 1.25rem;
            padding: 1rem;
        }

        @media (max-width: 881.98px) {
            //inset: auto 232px 1.5rem 1.5rem;
            top: auto;
            right: 232px;
            bottom: 1.5rem;
            left: 1.5rem;
            font-size: 1.2rem;
        }

        @media (max-width: 767.98px) {
            font-size: 1.1rem;
        }

        @media (max-width: 575.98px) {
            padding: .75rem 1rem;
            font-size: .8rem;
            //inset: auto 1rem 5.5rem 1rem;
            top: auto;
            right: 1rem;
            bottom: 5.5rem;
            left: 1rem;
            backdrop-filter: blur(2px);
            -webkit-backdrop-filter: blur(2px);
            font-weight: 600;
            line-height: 1.5em;
        }
    }
}